(function ($, window, document, undefined) {

    let pluginName = "migrant";

    function migrant(element, options) {
        this.element = $(element);

        this.settings = $.extend({
            mobW: 700, // ширина, меньше которой показываем вариант для мобильных устройств
            projectSelectOptions: [
                { label: 'Русский', value: 'rus' },
                { label: 'Кыргыз тили', value: 'kyrgyz' },
                { label: 'Тоҷикӣ', value: 'tajik' },
                { label: 'O\'zbek', value: 'uzbek' },
                { label: 'Azərbaycan', value: 'azer' },
            ],
            projectSelectFlagClass: ['select-flag_russia', 'select-flag_kyrgyzstan', 'select-flag_tajikistan', 'select-flag_uzbekistán', 'select-flag_azerbaijan'],
            projectSelectValue: 'rus',
            projectLogoMap: {},
            htmlMode: false,
        }, options);

        this.init();
    }

    $.extend(migrant.prototype, {

        setup: {
            isMobile: false, // информация о том, соответствует ли данная ширина экрана мобильной версии

        },

        init: function () {

            let self = this;

            self.setup = $.extend(self.setup, self.settings);

            let winW = $(window).width();

            if (winW <= self.setup.mobW) {
                self.setup.isMobile = true;
            }

            self.bind();
            self.flagSelect();
            self.selectLanguage();
            self.resize();
        },

        applePie: function(){
            /* Определяет apple-устройства */
            return ( navigator.userAgent.match(/(iPhone|iPod|iPad)/i) );
        },

        bind: function(){
            let self = this;

            /*$('body').on('click', '.submenu-toggler_js', function(e){
                e.preventDefault()
                e.stopPropagation()
                $(this).closest('.menu__submenu-container').toggleClass('open');
            });

            $('body').on('click', function(e){
                if (!($(e.target).parents('.menu__submenu-container').length || $(e.target).hasClass('..menu__submenu-container'))) {
                    $('.menu__submenu-container').removeClass('open');
                }
            });*/

            $('.bnr__slider').slick({
                dots: true,
                infinite: true,
                speed: 500,
                fade: true,
                arrows: true,
                cssEase: 'linear'
            });

            $('body').on('click', '.burger', function(){
                $(this).toggleClass('close');
                $('.mob-menu').toggleClass('open');
                $('html').toggleClass('fixed');
            });
        },

        flagSelect: function(){
            let self = this;

            VirtualSelect.init({
                ele: '.header-lang-select',
                options: self.settings.projectSelectOptions,
                selectedValue: self.settings.projectSelectValue,
                placeholder: 'Выберите язык',
                labelRenderer: sampleLabelRenderer,
                selectedLabelRenderer: sampleLabelRenderer
            });

            VirtualSelect.init({
                ele: '.mob-menu-lang-select',
                options: self.settings.projectSelectOptions,
                selectedValue: self.settings.projectSelectValue,
                placeholder: 'Выберите язык',
                labelRenderer: sampleLabelRenderer,
                selectedLabelRenderer: sampleLabelRenderer
            });

            function sampleLabelRenderer(data) {
                let prefix = '';

                /** skipping options those are added newly by allowNewOption feature */
                if (!data.isCurrentNew && !data.isNew) {
                    /** project developer has to add their own logic to create image/icon tag */
                    let flagIndex = data.index; //data.value % flagClasses.length;
                    prefix = `<i class="select-flag ${self.settings.projectSelectFlagClass[flagIndex]}"></i>`;
                } else {
                    /** common image/icon could be added for new options */
                }

                return `${prefix}${data.label}`;
            }

            $('.header-lang-select').change(function(e) {

                if (this.value && this.value !== self.settings.projectSelectValue) {
                    if (!!self.settings.projectLogoMap[this.value]) {
                        $('#logo').attr('src', self.settings.projectLogoMap[this.value]);
                        self.openUrl(this.value)
                    } else {
                        $('#logo').attr('src', `../images/logo-${this.value}.svg`);
                        document.querySelector('.mob-menu-lang-select').setValue(this.value, true);
                    }
                }
            });

            $('.mob-menu-lang-select').change(function(e) {
                if (this.value && this.value !== self.settings.projectSelectValue) {
                    if (!!self.settings.projectLogoMap[this.value]) {
                        $('#logo').attr('src', self.settings.projectLogoMap[this.value]);
                        self.openUrl(this.value)
                    } else {
                        $('#logo').attr('src', `../images/logo-${this.value}.svg`);
                        document.querySelector('.header-lang-select').setValue(this.value, true);
                    }
                }
            });

        },

        openUrl: function(url) {
            let _url = this.stripTrailingSlash(url);
            let currentUrl = this.stripTrailingSlash(document.location.href);
            if (_url !== currentUrl) {
                document.location.href = url
            }
        },

        stripTrailingSlash(text) {
            return text.split('/').filter(Boolean).join('/');
        },

        selectLanguage: function () {
            let self = this;
            let lang = self.getLanguage()

            // if language selected OR language-screen does not available

            if (!self.settings.htmlMode) {
                if (!!lang || $('.language-screen').length == 0) {
                    return
                }
            }


            $('html').addClass('fixed');
            $('.language-screen').show();

            $('body').on('mouseenter', '.lang-btn', function(e){
                $('#lg-screen-logo').attr('src', `../images/logo-${$(this).data('lang')}.svg`);
                /*$('.language-screen__sign span').hide();
                $(`.language-screen__sign span[data-lang=${$(this).data('lang')}]`).show();*/
            });

            $('body').on('click', '.lang-btn', function(e){
                $('.language-screen').fadeOut();
                document.querySelector('.header-lang-select').setValue($(this).data('lang'));
                self.setLanguage($(this).data('url'));

                if (!self.settings.htmlMode) {
                    self.openUrl($(this).data('url'));
                }
                $('html').removeClass('fixed');

                clearTimeout(langTimer1);
                clearTimeout(langTimer2);
                clearTimeout(langTimer3);
            });

            let langCounter = 1;

            let langTimer1, langTimer2, langTimer3, timerCounter = 0;

            langTimer1 = setTimeout(function tick() {
                if (timerCounter === 0) {
                    langTimer3 = setTimeout(function(){
                        $(`.language-screen__sign span:nth-child(${langCounter})`).fadeOut();
                    }, 2000);
                } else {
                    $(`.language-screen__sign span:nth-child(${langCounter})`).fadeOut();
                }

                timerCounter++;

                langTimer1 = setTimeout(function(){
                    langTimer2 = setTimeout(function(){
                        langCounter >= $('.language-screen__sign span').length ? langCounter = 1: langCounter++;
                        $(`.language-screen__sign span:nth-child(${langCounter})`).fadeIn();
                    }, 500);
                    tick();
                }, 2000);
            }, 1000);
        },

        setLanguage: function (val) {
            Cookies.set('language', val, { expires: 365 })
        },

        getLanguage: function() {
            return Cookies.get('language')
        },

        resize: function(){
            let self = this;

            $(window).on('resize', function () {
                let winW = $(window).width();

                if (winW <= self.setup.mobW && !self.setup.isMobile){
                    self.setup.isMobile = true;

                } else if (winW >= self.setup.mobW && self.setup.isMobile) {
                    self.setup.isMobile = false;

                }
            });
        },


    });


    $.fn[ pluginName ] = function (options) {
        this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new migrant(this, options));
            }
        });
        return this;
    };


})(jQuery, window, document);
