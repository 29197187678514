/***
 * JQuery - плагин "Показать еще" (вешается на кнопку "Показать еще")
 */
(function( $ ) {

    /**
     * options - структура, содержащая параметры:
     *  {
     *    listSelector : '#list_selector' - строка с селектором списка (нужна для того чтобы получить список в который добавлять подгружаемые элементы)
     *    loadingSelector : '#loading_selector' - строка с селектором блока loading (нужна чтобы кастомизировать иконку загрузки, если не указан - то после кнопки "Показать еще" строится дефолтный loading)
     *    ajax : { - параметр, который содержит основные опции для метода $.ajax
     *      url :'url',
     *      dataType : 'json',
     *      type : 'post',
     *      data : {},
     *      success : function(){},
     *      error: function(){}
     *      ...
     *    }
     *
     *    Из описанных выше параметров обязательными являются:
     *    1) ajax.url
     *    2) listSelector - обзятелен в том случае, если ajax.success не переопределен.
     *
     *    По умолчанию сервер должен возвращать json вида:
     *    {
     *      result: 'ok'/'error',
     *      data:'html код который буден вставлен в listSelector',
     *      isFinished: true/false (говорит о том, прилетели ли последние данные, нужно чтобы скрыть кнопку "Показать еще")
     *    }
     *  }
     */

    var pluginName = 'loadMore';

    /**
     * loadMore plugin
     * @param block
     * @param options
     */
    var loadMore = function (block, options)
    {
        this.block = block;

        // Список в который будет добавлятся загруженный контент
        this.list = (options.listSelector) ? $(options.listSelector) : false;

        // Селектор прелоадера получаем из опций, либо создаём прелоадер по умолчанию
        this.loading = (options.loadingSelector) ? $(options.loadingSelector) : this.createLoading();
        this.block.after(this.loading);

        // Параметры загрузки контента
        this.loadParams = options.ajax.data || {};

        // Текущая страница
        this.page = this.loadParams.page || 2;

        this.options = $.extend({}, options || {});

        this.init();
    }

    loadMore.prototype = {
        init: function ()
        {
            this.initBinds();
        },
        /**
         * Навешивание событий
         */
        initBinds: function ()
        {
            var self = this;

            this.block.on('click', function (e) {
                e.preventDefault();
                self.loadContent();
            });
        },
        /**
         * Получить параметры загрузки контента
         */
        getLoadParams : function()
        {
            return this.loadParams;
        },
        /**
         * Установить параметры загрузки контента
         * @param params
         */
        setLoadParams : function(params)
        {
            this.loadParams = params;
        },
        updateLoadParams : function(params)
        {
            $.extend(this.loadParams, params);
        },
        /**
         * Получить текущую страницу
         */
        getPage : function()
        {
            return this.page;
        },
        /**
         * Установить текущую страницу
         * @param params
         */
        setPage : function(page)
        {
            this.page = parseInt(page) || 1;
        },

        /**
         * Получить контейнер списка, в который идёт загрузка контента
         */
        getList : function()
        {
            return this.list;
        },
        /**
         * Очистить контейнер списка, в который идёт загрузка контента
         */
        clearList : function()
        {
            var list = this.getList();
            if (list)
            {
                $(list).empty();
            }
        },
        /**
         * Загрузка контента на основе текущих параметров
         */
        loadContent : function()
        {
            var self = this;

            self.block.hide();
            self.loading.show();

            var ajaxData = $.extend(
                {
                    dataType: 'json',
                    type: 'post',
                    data: self.getLoadParams(),
                    success: function (response)
                    {
                        self.loading.hide();

                        if (response.result == 'ok')
                        {
                            self.page++;
                            if (self.options.listSelector != false)
                            {
                                self.processNextPageResp(response.data);
                                //self.list.append(response.data);
                            }

                            if (!response.isFinished)
                            {
                                self.block.show();
                            }
                        }
                        else
                        {
                            self.block.show();
                        }

                        if (self.options.callbackSuccess instanceof Function)
                        {
                            self.options.callbackSuccess(response);
                        }
                    },
                    error: function ()
                    {
                        alert('Произошла ошибка');
                    }
                },
                self.options.ajax || {}
            );

            ajaxData.data.page = self.page;
            $.ajax(ajaxData);
        },
        /**
         * Обработка загруженных данных для следующей страницы
         */
        processNextPageResp: function (data) {
            var self = this;
            var list = $('<div>').append(data);
            $(list).children().each(function() {
                var itemId = $(this).data('itemId');
                if (self.list.children('[data-item-id='+itemId+']').length == 0) {
                    self.list.append(this);
                }
            });
        },
        /**
         * Создание прелоадера
         */
        createLoading: function ()
        {
            return $('<div class="spinner">');
        }
    };

    $.fn.loadMore = function (method)
    {
        var data = this.data(pluginName);

        var obj = (!data) ? new loadMore(this, method) : data.obj;

        if (!data)
        {
            this.data(pluginName, {
                obj: obj
            });
        }

        // логика вызова метода
        if (typeof method !== 'object' && obj[method])
        {
            var args = Array.prototype.slice.call(arguments, 1);
            return obj[ method ].apply(obj, args);
        }

        return this;
    }

})( jQuery );
/* ---------------- END ------------------*/
